import styles from "./App.module.scss";

interface IWork {
  icon: string;
  iconStyle?: "lightIcon" | "darkIcon";
  name: string;
  description: string;
  href: string;
}

export default function Work({
  icon,
  iconStyle = "darkIcon",
  name,
  description,
  href,
}: IWork) {
  return (
    <a className={styles.work} href={href} target="_blank" rel="noreferrer">
      <div className={styles.iconLine}>
        <div className={[styles.iconBox, styles[iconStyle]].join(" ")}>
          <img src={icon} alt="Axe API" height={60} width={60} />
        </div>
      </div>
      <div className={styles.workName}>{name}</div>
      <div className={styles.workDescription}>{description}</div>
    </a>
  );
}
