import styles from "./App.module.scss";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import {
  GitHubIcon,
  LinkedInIcon,
  ProductHuntIcon,
  MeetIcon,
  YoutubeIcon,
} from "./Icons";
import Work from "./Work";

function App() {
  return (
    <div className={styles.container}>
      <div className={styles.page}>
        <div className={styles.imageLine}>
          <img
            src="/ozgur-adem-isikli.png"
            alt="Ozgur Isikli"
            height={160}
            width={160}
            className={styles.image}
          />
        </div>
        <header className={styles.header}>
          <h1 className={styles.title}>
            I'm Ozgur <span className={styles.dash}>-</span>{" "}
            <span>sr. software developer</span>
          </h1>
          <p className={styles.description}>
            I love creating web apps. I have a decade of experience with
            different technologies.
          </p>
        </header>
        <div className={styles.links}>
          <a
            href="https://github.com/ozziest"
            target="_blank"
            rel="noreferrer"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="This is my CV!"
          >
            <GitHubIcon />
          </a>
          <a
            href="http://linkedin.com/in/ozguradem"
            target="_blank"
            rel="noreferrer"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="My professional social media account"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://www.youtube.com/@OzgurAdemISIKLI"
            target="_blank"
            rel="noreferrer"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Live coding, Axe API videos, etc."
          >
            <YoutubeIcon />
          </a>
          <a
            href="https://www.producthunt.com/@iozguradem"
            target="_blank"
            rel="noreferrer"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="What I do make?"
          >
            <ProductHuntIcon />
          </a>
          <a
            href="https://superpeer.com/iozguradem/-/general"
            target="_blank"
            rel="noreferrer"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Call me on Superpeer!"
          >
            <MeetIcon />
          </a>
        </div>
      </div>
      <h2 className={styles.workTitle}>Works</h2>
      <div className={styles.works}>
        <Work
          href="https://axe-api.com/"
          icon="/axe.png"
          name="Axe API"
          description="It is next-generation Rest API framework that you can quickly create
            a Rest API by simply defining models."
        />
        <Work
          href="https://ikinciel.dev"
          icon="/secondhand.svg"
          iconStyle="lightIcon"
          name="secondhand.dev"
          description="It is a private marketplace for developers to sell second-hand devices."
        />
        <Work
          href="https://validator.axe-api.com/"
          icon="/axe.png"
          name="Robust Validator"
          description="It is a rule-based data validation for JavaScript apps"
        />
      </div>
      <Tooltip id="my-tooltip" place="bottom" />
    </div>
  );
}

export default App;
